<script setup lang="ts">
import { useWindowSize } from '@vueuse/core';

// Props
const value = defineModel<boolean>();
const props = defineProps<{
  title?: string;
  width?: string;
  description?: string;
}>();

// Composables
const { width: windowWidth, height } = useWindowSize();

// Computed
const isSmallerThanWidth = computed(() => {
  if (props.width) {
    return windowWidth.value <= parseInt(props.width) + 32;
  }
  return true;
});
</script>

<template>
  <dialog-root v-model:open="value">
    <dialog-portal>
      <dialog-overlay class="fixed inset-0 z-30 bg-gray-500/75 transition-opacity" />
      <dialog-content
        class="fixed top-[50%] left-[50%] z-[100] flex flex-col rounded-lg bg-white shadow-lg translate-x-[-50%] translate-y-[-50%]"
        :class="isSmallerThanWidth ? '!w-[calc(100vw-32px)]' : ''"
        :style="`width: ${width}px; max-height: ${height - 32}px`"
      >
        <visually-hidden>
          <dialog-title class="text-base font-semibold leading-6 text-gray-900"></dialog-title>
          <dialog-description class="mt-2 text-sm text-gray-500"></dialog-description>
        </visually-hidden>
        <div v-if="title || description || $slots.header" class="border-b border-gray-200 p-4 flex items-center justify-between">
          <slot name="header">
            <dialog-title v-if="title" class="text-base font-semibold leading-6 text-gray-900">
              {{ title }}
            </dialog-title>
            <dialog-description v-if="description" class="mt-2 text-sm text-gray-500">
              {{ description }}
            </dialog-description>
          </slot>
          <dialog-close
            class="h-6 w-6 rounded-sm cursor-pointer flex items-center justify-center text-gray-400 hover:text-gray-500"
            aria-label="Close"
          >
            <ui-icon name="X" class="w-5 h-5" />
          </dialog-close>
        </div>

        <div class="flex-auto overflow-auto p-4" :class="!$slots.footer ? 'pb-8' : ''">
          <slot />
        </div>

        <div v-if="$slots.footer" class="border-t border-gray-200 px-4 py-4 flex items-center justify-end gap-2">
          <slot name="footer" />
        </div>
      </dialog-content>
    </dialog-portal>
  </dialog-root>
</template>
